import { imageLoader } from 'next-image-loader/build/image-loader'

const IMAGE_HOST = 's3.lifedot.jp'

imageLoader.loader = ({ src, width, quality }) => {
  const widthAndQuality = `w=${width}&q=${quality || 75}`

  if (src.includes(IMAGE_HOST)) return `${src}?${widthAndQuality}`

  return `${
    process.env.NEXT_PUBLIC_IMAGE_PATH
      ? process.env.NEXT_PUBLIC_IMAGE_PATH
      : '/_next/image'
  }?url=${encodeURIComponent(src)}&${widthAndQuality}`
}
